<template>
    <div class="mobileTourn">
        <div v-for="(tourn, index) in nextTournament" :key="index">
            <b-row class="image">
                <b-col v-if="tourn.course_images == 'Y'" class="imagewidth" v-bind:style="{ 'background-image': 'url(' + 'https://ocs-asia.sgp1.cdn.digitaloceanspaces.com/apga/courses/' + season + '/' + tourn.course_code +'/' + 'File_1.jpg' + ')' }">
                </b-col>
                <b-col v-else class="imagewidth" v-bind:style="{ 'background-image': 'url(' + 'https://assets.asiantour.com/asian-tour/2022/06/sliderdummycourse.png)' }">
                </b-col>
            </b-row>  
            <b-row class="nextbtnColor">
                <b-col>
                    <p class="fullName">{{tourn.short_name}}</p>
                    <p class="dicDates">{{tourn.dic_dates}}</p>
                </b-col>
            </b-row> 
            <b-row class="NextBottomBlie">
                <b-col>
                    <div class="FullListingBtn">
                        <b-link @click="toggle" :to="'/tournament/' + tourn.code + '/' + season" class="btn btn-secondary" target="_self">View Tournament</b-link>
                    </div>
                </b-col>
            </b-row>   
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Next Tourn",
  props: ["season", 'config'],
  components: {
  },
  data() {
    return {
      tm_params: [],
      nextTournament: [],
      open: false,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    isMobile() {
      if( screen.width <= 768 ) {
        return true;
      }
      else {
        return false;
      }
    },
  },
  mounted() {
    setTimeout(() => {
    axios //add API Call
      .get(
        process.env.VUE_APP_TIC_BASE +
          'apga' +
          "/" +
          this.season +
          "/tmticx?randomadd=" +
          new Date().getTime()
      ) // Page number changed on each page based on JSON page id you are calling
      .then(response => {
        this.tm_params = response.data.tournaments.tournaments_entry
        this.code = response.data.code
        let arr = []
        var t_add=0
        this.nextTournament = arr
        // console.log("this.nextTournament")
        // console.log(this.nextTournament)
        if (this.reports == '') {
            this.tm_params.forEach((value, key) => {
                if (value.code == this.code) {
                        t_add = 0;
                        for (var i = 0; i <= t_add; i++) {
                            arr.push(response.data.tournaments.tournaments_entry[key + i]);
                            // console.log("this.nextTournament --")
                            // console.log(arr)
                        }
                }
            });
        } else {
            this.tm_params.forEach((value, key) => {
                if (value.code == this.code) {
                    if (response.data.ts_final_result=='Y') {
                        t_add = 1;
                        for (var i = 1; i <= t_add; i++) {
                            arr.push(response.data.tournaments.tournaments_entry[key + i]);
                            // console.log("this.nextTournament --")
                            // console.log(arr)
                        }
                    } else {
                        t_add = 0;
                        for (var w = 0; w <= t_add; w++) {
                            arr.push(response.data.tournaments.tournaments_entry[key + w]);
                            // console.log("this.nextTournament --")
                            // console.log(arr)
                        }
                    }
                }
            });   
        }
      })
    }, 1000)
  },
};
</script>

<style scoped>
.mobileTourn {
    margin-top: 50px;
}
.innerLogo {
    background-color: #fff;
    height: 120px;
    width: 120px;
    position: absolute;
    border-radius: 105px;
    top: 10.3em;
    left: 127px;
    right: 55px;
}
img.logoImage {
    width: 70px;
    text-align: center;
    margin: auto;
    display: block;
    position: relative;
    top: 25px;
}
img.logoImageDefault {
    width: 95px;
    text-align: center;
    margin: auto;
    display: block;
    position: relative;
    top: 43px;
}
.dicDates {
    color: #6d6c6c;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 13px;
}
.gradHome {
    background-color: #5897E6;
    background-image: linear-gradient(to right, #5897E6, #A8B7C9);
    height: 17px;
}
.fullName {
    color: #0A3F7F;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    padding-top: 15px;
}
.course {
    font-size: 16px !important;
    color: #fff;
    font-weight: 300;
}
.image {
    margin-left: 0;
    margin-right: 0px;
}
.imagewidth {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 150px;
}
.nextbtnColor {
    background-color: #ffffff;
    margin-left: 0;
    margin-right: 0px;
    border: 1px solid #e3e0e0;
}
.FullListingBtn > a.btn {
    background-color: #042F62;
    border-radius: 0;
    color: #fff !important;
    width: 100%;
    height: 43px;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 2.1;
    border-color: transparent;
}
.FullListingBtn > a.btn:hover {
    background-color: #5897E6;
    border-radius: 0;
    color: #fff !important;
    width: 100%;
    height: 43px;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 2.1;
    border-color: transparent;
}
@media only screen and (max-width: 1200px) {
    .nextbtnColor {
        background-color: #093e7c;
        height: auto;
        margin-left: 0;
        margin-right: 0px;
        padding: 20px;
        padding-top: 30px;
    }
    .logoImageMob {
        width: 75px;
        text-align: center;
        margin: auto;
        display: block;
        position: relative;
        top: 20px;
    }
    .innerLogoMob {
        background-color: #fff;
        height: 120px;
        width: 120px;
        position: absolute;
        border-radius: 105px;
        top: 13.3em;
        left: 155px;
        right: 70px;
    }
}
@media only screen and (max-width: 768px) {
    .mobileTourn {
        margin-bottom: 30px;
    }
}
@media only screen and (max-width: 375px) {
    .innerLogoMob {
        background-color: #fff;
        height: 120px;
        width: 120px;
        position: absolute;
        border-radius: 105px;
        top: 13.3em;
        left: 125px;
        right: 70px;
    }
}
</style>
